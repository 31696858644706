/**
 * 全站权限配置
 *
 */
import router from '@/router';

/**
 * 导航守卫，相关内容可以参考:
 * https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
 */
router.beforeEach((to, from, next) => {
    let html = document.getElementsByTagName('html')[0]
    html.scrollTop = 0
    next()

});

router.afterEach(() => {});