<template>
  <footer class="footer">
    <div class="section">
      <div class="footer_box">
        <el-row :gutter="20">
          <!-- {{ { zh_CN: '中文', en_US: 'English', ja_US: '日本語' }[$store.state.language] }} -->
          <!-- {{$store.state.language}} -->
          <el-col :span="9" v-if="$store.state.language=='zh_CN'">
            <img class="logo-footer" src="@/assets/images/logo.png" alt="" />
            <div class="add_p">扫码添加客服微信，关注公众号</div>
            <div class="img_cord_div">
              <img class="img_cord" src="@/assets/images/qrCode/weChatCode.jpg" alt="" />
              <img class="img_cord" src="@/assets/images/qrCode/official-account.png" alt="" />
            </div>
            <div class="icon_div">
              <img class="img_cord" src="@/assets/images/bottom_icon/icon-douyin.png" alt="" />
              <img class="img_cord" src="@/assets/images/bottom_icon/icon-weiban.png" alt="" />
              <img class="img_cord" src="@/assets/images/bottom_icon/icon-xiaohongshu.png" alt="" />

            </div>
          </el-col>
          <el-col :span="9" v-else-if="$store.state.language=='en_US'">
            <img class="logo-footer" src="@/assets/images/logo.png" alt="" />
            <div class="icon_div">
              <img class="img_cord" src="@/assets/images/bottom_icon/icon-douyin.png" alt="" />
              <img class="img_cord" src="@/assets/images/bottom_icon/icon-weiban.png" alt="" />
              <img class="img_cord" src="@/assets/images/bottom_icon/icon-xiaohongshu.png" alt="" />
              <div class="msg_div">
                <i class="el-icon-message"></i>
                admin@ChatExcel.com
              </div>
            </div>
          </el-col>

          <el-col :span="5" v-if="$store.state.language=='zh_CN'">
            <div class="menu_tit">其他产品</div>
            <!-- <div>
              <el-link href="https://www.yuankongai.com/#/popularFeelings" target="_blank">AI营销辅助决策</el-link>
            </div>
            <div>
              <el-link>AI智能客服</el-link>
            </div> -->
            <div>
              <el-link> <a target="_blank" rel=”nofollow” class="el_a" href="https://www.yingkeyun.com/ma.html"> AI营销自动化 </a> </el-link>

              <!-- <el-link  target="_blank">AI营销自动化</el-link> -->
            </div>
            <div>
              <el-link> <a target="_blank" rel=”nofollow” class="el_a" href="https://www.yingkeyun.com/healing-drugsPage.html"> AI医疗营销 </a> </el-link>
              <!-- <el-link href="https://www.yingkebao.com/healing-drugsPage.html" target="_blank">AI医疗营销</el-link> -->
            </div>
          </el-col>
          <el-col :span="5">
            <div class="menu_tit"> {{$t(`footer_config.about_us`)}}</div>
            <div>
              <el-link href="#/introducePage" target="_blank">{{$t(`footer_config.introduce`)}}</el-link>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="menu_tit">{{$t(`footer_config.update_dynamic`)}}</div>
            <div>
              <el-link href="#/updateLog" target="_blank">{{$t(`footer_config.update_log`)}}</el-link>
            </div>
          </el-col>
        </el-row>
        <div class="beian_div">
          <a target="_blank" class="beian-link" href="https://beian.miit.gov.cn/">
            Copyright<img class="img_cord" src="@/assets/images/copyright.png" style="width:12px" alt="" />北京元空智能科技有限公司 | 京ICP备05070602号
          </a>
          <div class="btn_div">
            <a target="_blank" class="beian-link" href="https://beian.miit.gov.cn/">
              <img class="img_cord" src="@/assets/images/beian.jpg" style="width:20px" alt="" /> 京公网安备11010102000001号
            </a>
          </div>

          <!-- |<a target="_blank" @click="clickImg" class="a_me"> 联系我们 </a> -->
        </div>
      </div>

    </div>

    <div class="section" style="display:none">
      <img class="logo-footer" src="@/assets/images/logo.png" alt="" />
      <p class="copyright">
        北京元空智能科技有限公司 |
        <a target="_blank" class="beian-link" href="https://beian.miit.gov.cn/">
          京ICP备2024050141号-3
        </a>
        |<a target="_blank" @click="clickImg" class="a_me"> 联系我们 </a>
        <!-- <a
          target="_blank"
          class="beian-link"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010402351499"
          >本网站支持 IPv6 上海尔骋科技有限公司</a
        > -->
      </p>
    </div>
    <el-dialog title="" :visible.sync="showDialog" width="400px">
      <img class="callme_img" src="@/assets/images/callme.png" alt="" />
    </el-dialog>
  </footer>
</template>

<script>
export default {
  name: "myFooter",
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    clickImg() {
      this.showDialog = true
      console.log(543)
    },
  }
};
</script>

<style scoped>
.btn_div{
  margin-top: 10px;
}
.btn_div a{
  display: flex;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center
}
/* 当屏幕宽度小于1400px时，应用以下样式 */
@media screen and (max-width: 1400px) {
  .section {
    min-width: 1000px;
    width: 1000px;
    max-width: 1000px;
  }
}
.beian_div {
  border-top: solid 1px #eee;
  margin-top: 20px;
  padding-top: 10px;
  text-align: center;
}
.callme_img {
  width: 100%;
}
.a_me {
  cursor: pointer;
}
.footer_box {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
}
.logo-footer {
  width: 170px;
  height: 30px !important;
}
.footer_box .add_p {
  margin-top: 12px;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.img_cord_div {
  display: flex;
}
.img_cord_div img {
  border-radius: 4px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  margin-right: 8px;
  margin-top: 6px;
}
.icon_div {
  margin-top: 20px;
}
.icon_div img {
  width: 18px;
  margin-right: 12px;
}
.msg_div {
  display: flex;
  vertical-align: middle;
  align-items: center;
}
.msg_div i {
  margin-right: 4px;
  font-size: 16px;
}
.menu_tit {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 12px;
}
/deep/ .el-link {
  /* display: flex;
  text-align: left;
  justify-content: left;
  width: auto; */
  margin-bottom: 20px;
}
/deep/ .el-link.el-link--default:hover {
  color: #35ad84;
}
/deep/ .el-link::after {
  border-bottom-color: #35ad84 !important; /* 将 #FF0000 替换为您想要的颜色 */
}
.el_a {
  text-decoration: none;
  color: #606266;
}
.el_a:hover {
  color: #35ad84;
}
</style>