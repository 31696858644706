// betawww.chatexcel.com 测试
// yuankongai  正式
let domainName = {}
if (window.location.origin == 'https://betawww.chatexcel.com' || process.env.NODE_ENV == 'development') { //测试和开发
    domainName = {
        api1: 'https://betawww.chatexcel.com',
        api3: 'https://betaagent.chatexcel.com', //测试
        api4: "http://18.246.191.34:8100", ///
        api5: "https://betaaicanvas.chatexcel.com",
        api6: "https://betaapp.chatexcel.com",
        api7: "https://betaagent.chatexcel.com",
        api8: "https://betasheet.chatexcel.com",
        api9: "https://betaworkspace.chatexcel.com",
        api10: "https://betam.chatexcel.com"
    }
} else {
    domainName = {
        api1: 'https://www.chatexcel.com',
        api3: 'https://agent.chatexcel.com', //测试
        api4: "http://18.246.191.34:8100", ///
        api5: "https://aicanvas.chatexcel.com",
        api6: "https://app.chatexcel.com",
        api7: "https://agent.chatexcel.com",
        api8: "https://sheet.chatexcel.com",
        api9: "https://workspace.chatexcel.com",
        api10: "https://m.chatexcel.com"
    }
}
export { domainName }