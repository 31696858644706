let domain;
console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === 'development') {
    domain = document.domain
} else {
    domain = 'chatexcel.com'
}
// const domain = '192.168.1.42' 
/** 根据键值设置cookie值,  [millisecond为过期的毫秒值] */
export function setCookie(key, value, millisecond) {
    if (window.jsbridge) {
        // 检查是否提供了有效的毫秒数
        if (typeof millisecond === 'number' && millisecond > 0) {
            console.log('ifsetCookie')
            const dateGmt = new Date(new Date().getTime() + millisecond).toGMTString();
            document.cookie = `${key}=${encodeURIComponent(value)}; expires=${dateGmt}; path=/; domain=${domain}`;
        } else {
            // 如果没有提供过期时间，则创建一个会话cookie（浏览器关闭后即失效）
            document.cookie = `${key}=${encodeURIComponent(value)}; path=/; domain=${domain};Secure;SameSite=None`;
        }
    } else {
        let str = `${key}=${value};domain=${domain};`;
        if (millisecond != "undefined") {
            var dateGmt = new Date(new Date().getTime() + millisecond).toGMTString();
            str += "expires=" + dateGmt;
        }
        const encodedValue = encodeURIComponent(value);
        // document.cookie = str;
        document.cookie = `${key}=${encodedValue};domain=${domain};`
    }
}

/*
    根据键删除cookie
*/
export function removeCookie(key) {
    document.cookie = `${key}=*;domain=${domain};expires=${new Date(new Date().getTime() - 1000).toGMTString()}`
}

/*
    根据键获取cookie值, 若未获取到返回 null
*/
export function getCookie(key) {
    let cookiesStr = document.cookie;
    if (cookiesStr == "") {
        return null;
    }

    let index = cookiesStr.indexOf(key + "=");
    if (index == -1) {
        return null;
    }
    index = index + key.length + 1;

    let cookieStr = cookiesStr.substring(index)
    cookieStr = decodeURIComponent(cookiesStr.substring(index))
    let subIndex = cookieStr.indexOf(";");
    if (subIndex != -1) {
        // cookieStr = cookieStr.substring(0, subIndex);
        cookieStr = decodeURIComponent(cookieStr.substring(0, subIndex))
    }
    return cookieStr;
}

/** 清除所有cookie */
export function clearCookies() {
    var cookieObj = getAllCookies();
    for (const key in cookieObj) {
        removeCookie(key);
    }
}

/** 获取所有cookie, 返回一个cookie对象 */
export function getAllCookies() {
    let cookiesStr = document.cookie;
    let cookieObj = new Object();

    // 当前cookie为空时,返回空对象
    if (cookiesStr == "") {
        return cookieObj;
    }

    var cookieArr = cookiesStr.split(";");

    for (let index = 0; index < cookieArr.length; index++) {
        let cookieKey = cookieArr[index].substring(0, cookieArr[index].indexOf("=")).trim();

        let cookieVal = cookieArr[index].substring(cookieArr[index].indexOf("=") + 1).trim();

        cookieObj[cookieKey] = cookieVal;
    }

    return cookieObj;
}